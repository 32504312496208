import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/videoprocessor/VDWALL LVP615S/Product-Page.json";
import Datasheet from "../../../../content/produk/controller/videoprocessor/VDWALL LVP615S/datasheet-vdwall-lvp615s.pdf";
import ProductImage from "../../../../images/product/controller/videoprocessor/VDWALL LVP615S/Product-Summary-VDWALL-LVP615S.jpg";
const rgblinkx1 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Video Processor VDWALL LVP615S</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <h2>Product Summary</h2>
                        <p style={{ textAlign: "justify" }}>
                            LVP615 adalah processor layar LED dengan 10-bit
                            Faroudja®️ DCDI Cinema Processing dan Faroudja®
                            TrueLife™ video enhancer dengan kemampuan switching
                            seamless, fade dan blend.
                        </p>
                        <ul style={{ textAlign: "justify" }}>
                            <li>
                                New generation Faroudja® Real Color®
                                professional LED video processor，10+ Bit
                                Faroudja® DCDI Cinema Processing Faroudja®
                                TureLife™ video enhancer.
                            </li>
                            <li>
                                Seamless Switching、Fade in Fade out
                                Switching、Blend Switching
                            </li>
                            <li>
                                Two signal switching ways：one key switch and
                                Pre. +Take switch
                            </li>
                            <li>
                                3 sets of custom dual picture display mode, one
                                key switch between modes. Any PIP/PBP display.
                            </li>
                            <li>
                                Adjust each YUV format signal channel color.
                            </li>
                            <li>
                                Input signal hot spare function to ensure output
                                more stable.
                            </li>
                            <li>
                                AIAO (Any In Any Out) function, any interception
                                and any output.
                            </li>
                            <li>
                                Custom input DVI EDID to realize perfect pixel
                                to pixel display.
                            </li>
                            <li>
                                Custom output format. Single unit maximum output
                                horizontal 3840 pixels or vertical 1920 pixels.
                            </li>
                            <li>
                                DVI Loop compatible with Macbook. DVI Loop
                                unlimited cascade to realize sync. Mosaic to
                                drive indefinite LED lattice. Frame sync
                                technology . No miss alignment or delay between
                                two adjacent image outputs.
                            </li>
                            <li>Supports RTC clock record function.</li>
                            <li>
                                Supports Chinese, English, Spanish, Russian and
                                Korean language.
                            </li>
                            <li>
                                A variety of control methods：wireless
                                WIFI、front panel button、infrared remote
                                control、PC software，Flexible operation.
                            </li>
                            <li>
                                2 channels configurable external audio inputs.
                                Plus HDMI,DP and SDI total 5 channels audio sync
                                switch.
                            </li>
                            <li>
                                Built in 2 pieces LED sending cards(compatible
                                4*RJ45 ports sending cards.Cards are optional.)
                            </li>
                            <li>
                                24/7 application，high reliability and
                                stability.
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

rgblinkx1.propTypes = {
    siteTitle: PropTypes.string,
};

rgblinkx1.defaultProps = {
    siteTitle: ``,
};

export default rgblinkx1;
